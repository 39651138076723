import { localeCs, localeEn } from "@olc/vue-grid";

const datetimeFormats = {
  en: {
    short: {
      year: "numeric", month: "short", day: "numeric"
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    }
  },
  cs: {
    short: {
      year: "numeric", month: "short", day: "numeric"
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    }
  }
};

export default defineI18nConfig(() => ({
  ...localeCs,
  ...localeEn,
  datetimeFormats,
  fallbackLocale: {
    sk: ["cs", "en"],
    default: ["en"]
  }
}));
